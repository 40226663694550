import React, { type FC, type ReactElement } from "react";

type TGuestParticipantNoSessionsMessageProps = {
    message: string;
};

const GuestParticipantNoSessionsMessage: FC<TGuestParticipantNoSessionsMessageProps> = ({
    message
}: TGuestParticipantNoSessionsMessageProps): ReactElement => <p className="text-center font-bold h3">{message}</p>;

export { GuestParticipantNoSessionsMessage };
