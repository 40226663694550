import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Participant } from "main-app/models/participants";

import Pagination from "../Pagination";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { withTranslation } from "common/utils/lang";
import { WarningMessage } from "main-app/components/onboarding/components/WarningMessage";
import { TableLoader } from "../TableLoader";

interface IProps {
    participants: Participant[];
    loading: boolean;
    searchValue: string;
    error?: string | null;
    selectedCohort?: string;
}

const PAGE_SIZE = 20;

const ParticipantsTable: React.FC<IProps> = ({ participants, loading, searchValue, error }) => {
    const [sortedData, setSortedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (participants?.length > 0) {
            setSortedData(participants);
        }
    }, [participants]);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
        const lastPageIndex = firstPageIndex + PAGE_SIZE;
        if (sortedData?.length > 0) {
            return sortedData.slice(firstPageIndex, lastPageIndex);
        }
        return [];
    }, [currentPage, sortedData]);

    const noSearchResults = sortedData?.length === 0 && searchValue?.length > 0;
    const noParticipants = sortedData?.length === 0;

    const sortByName = useCallback((order: boolean) => {
        setSortedData(prev =>
            [...prev].sort((a, b) =>
                order ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName)
            )
        );
    }, []);

    const sortByModule = useCallback((order: boolean) => {
        setSortedData(prev =>
            [...prev].sort((a, b) =>
                order
                    ? withTranslation(a.currentModule?.versionName)?.localeCompare(
                          withTranslation(b.currentModule?.versionName)
                      )
                    : withTranslation(b.currentModule?.versionName)?.localeCompare(
                          withTranslation(a.currentModule?.versionName)
                      )
            )
        );
    }, []);

    const tableStatusText = useMemo(() => {
        if (noSearchResults) {
            return "We couldn’t find any results";
        }

        if (noParticipants) {
            return "No participants";
        }

        return "";
    }, [noSearchResults, noParticipants]);

    return (
        <Suspense fallback={<TableLoader loading={loading} />}>
            <div className="table-wrapper" data-testid="participants-table">
                {!loading && (
                    <div
                        className={classNames("table-results-text", {
                            active: noSearchResults || noParticipants || error
                        })}
                    >
                        {error ? <WarningMessage message={error} /> : tableStatusText}
                    </div>
                )}
                <table className="table table-no-head-mobile valign-middle text-left text-lg-center">
                    <TableHeader onSortByName={sortByName} onSortByModule={sortByModule} />
                    <tbody>{currentTableData?.map(item => <TableRow key={item.id} participant={item} />)}</tbody>
                </table>
            </div>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={sortedData.length}
                pageSize={PAGE_SIZE}
                onPageChange={page => setCurrentPage(page)}
            />
        </Suspense>
    );
};

export default ParticipantsTable;
