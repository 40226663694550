import React, {
    type FC,
    type ReactElement,
    type NamedExoticComponent,
    type Dispatch,
    type SetStateAction,
    type LazyExoticComponent,
    startTransition,
    Suspense,
    useCallback,
    useDeferredValue,
    useMemo,
    useState,
    memo,
    lazy
} from "react";
import { useTranslation } from "react-i18next";
import { type NavigateFunction, type Params, useNavigate, useParams } from "react-router-dom";

import CareTeamLink from "main-app/shared/CareTeamLink";
import {
    type IAvailableGuestSessionModel,
    type TUseGetAvailableGuestSessions,
    useGetAvailableGuestSessions
} from "main-app/api/use-get-available-guest-sessions";
import useMediaQuery from "common/hooks/use-media-query";
import { isNullOrUndefined } from "common/utils/gates";
import { MAX_WIDTH_MOBILE_MEDIA } from "main-app/constants";
import { FullSpinner } from "main-app/shared/spinner";
import useCoachingSessions from "main-app/api/use-coaching-sessions";
import useCoach from "main-app/api/use-coach";
import Coach from "main-app/models/coach";
import {
    type TUseAvailableGuestSessionsMutation,
    useAvailableGuestSessionsMutation
} from "main-app/api/mutations/use-available-guest-sessions";
import type { TEmptyCallback } from "main-app/shared/types/functions";

import { GuestParticipantHeading } from "./GuestParticipantHeading";
import {
    GuestParticipantConfirmSwapModal,
    type TGuestParticipantConfirmSwapModalSubmitFunction
} from "./GuestParticipantConfirmSwapModal";
import { GuestParticipantNoSessionsMessage } from "./GuestParticipantNoSessionsMessage";
import { GuestParticipantSlider } from "./GuestParticipantSlider";
import type { TGuestParticipantSessionBlockProps } from "./GuestParticipantSessionBlock";
import type { IOriginalSession } from "../types";

const GuestParticipantSessionBlock: LazyExoticComponent<FC<TGuestParticipantSessionBlockProps>> = lazy(
    () => import("./GuestParticipantSessionBlock")
);

const GuestParticipantContainerComponent: FC = (): ReactElement => {
    const { t } = useTranslation();

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA);

    const navigate: NavigateFunction = useNavigate();

    const { sessionId }: Readonly<Params> = useParams();

    const { data: coachData } = useCoach();

    const { data: coachingSessions, isLoading: areCoachingSessionLoading } = useCoachingSessions({
        enabled: !!sessionId
    });

    const originalSession: IOriginalSession | undefined = useMemo(
        (): IOriginalSession | undefined =>
            coachingSessions?.upcoming_sessions?.find(
                (s: { session_id: number }): boolean => s?.session_id === +sessionId
            ),
        [coachingSessions?.upcoming_sessions, sessionId]
    );

    const {
        data: availableSessionsData,
        isError: isErrorWithAvailableSessionsData,
        isLoading: areAvailableSessionsLoading,
        error: availableSessionsError
    }: TUseGetAvailableGuestSessions = useGetAvailableGuestSessions({
        id: +sessionId,
        enabled: !!sessionId
    });

    const coach: Coach = useDeferredValue<Coach>(coachData);
    const availableSessions: IAvailableGuestSessionModel[] =
        useDeferredValue<IAvailableGuestSessionModel[]>(availableSessionsData);

    const [isSwapSessionModalOpen, setIsSwapSessionModalOpen]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false);

    const handleCloseSwapSessionModal: TEmptyCallback = useCallback(
        (): void =>
            startTransition(
                (): void => (
                    setIsSwapSessionModalOpen(false),
                    setSuggestedSession(undefined),
                    setIsSessionConfirmed(false),
                    navigate(-1)
                )
            ),
        [navigate]
    );

    const handleOpenSwapSessionModal: TEmptyCallback = useCallback(
        (): void => startTransition((): void => setIsSwapSessionModalOpen(true)),
        []
    );

    const [suggestedSession, setSuggestedSession]: [
        IAvailableGuestSessionModel,
        Dispatch<SetStateAction<IAvailableGuestSessionModel>>
    ] = useState<IAvailableGuestSessionModel>();

    const handleSetSuggestedSession: (session: IAvailableGuestSessionModel) => void = useCallback(
        (session: IAvailableGuestSessionModel): void => setSuggestedSession(session),
        []
    );

    const {
        isSuccess: isSwapMutationSuccessful,
        error: swapMutationError,
        mutateAsync: handleSwapAvailableSessionMutation
    }: TUseAvailableGuestSessionsMutation = useAvailableGuestSessionsMutation();

    const [isSessionConfirmed, setIsSessionConfirmed]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false);

    const handleSubmitSwapSessionsClick: TGuestParticipantConfirmSwapModalSubmitFunction = useCallback(
        async ({ suggestedSessionId }: { suggestedSessionId: number }): Promise<void> =>
            isSessionConfirmed
                ? handleCloseSwapSessionModal()
                : (await handleSwapAvailableSessionMutation(
                      { originalSessionId: originalSession?.session_id, swappedSessionId: suggestedSessionId },
                      {
                          onSuccess: (): void => startTransition(() => setIsSessionConfirmed(true))
                      }
                  ),
                  void 0),
        [isSessionConfirmed, handleSwapAvailableSessionMutation, originalSession?.session_id]
    );

    function renderOriginalSessionBlock(): ReactElement {
        return areCoachingSessionLoading ? (
            <FullSpinner />
        ) : (
            <Suspense fallback={<FullSpinner />}>
                {!isNullOrUndefined(originalSession) && (
                    <GuestParticipantSessionBlock
                        isOriginal
                        coachPhoto={originalSession.coach?.photo}
                        coachName={originalSession.coach?.first_name}
                        coachLastName={originalSession.coach?.last_name}
                        sessionTime={originalSession?.session_time}
                    />
                )}
            </Suspense>
        );
    }

    function renderSliderContent(): ReactElement {
        return areAvailableSessionsLoading ? (
            <FullSpinner />
        ) : isErrorWithAvailableSessionsData ? (
            <GuestParticipantNoSessionsMessage message={String(availableSessionsError?.response?.data)} />
        ) : !availableSessions?.length ? (
            <GuestParticipantNoSessionsMessage message={t("participantSide.swapSessionsScreen.noSession")} />
        ) : (
            <Suspense fallback={<FullSpinner />}>
                <GuestParticipantSlider
                    handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                    handleSetSuggestedSession={handleSetSuggestedSession}
                    coach={coach}
                    availableSessions={availableSessions}
                />
            </Suspense>
        );
    }

    return (
        <>
            <section className="swap-session__container">
                <div className="swap-session__wrapper">
                    {renderOriginalSessionBlock()}
                    {isMobile && <GuestParticipantHeading />}
                    {renderSliderContent()}
                </div>
                <CareTeamLink
                    text={t("If you experience any issues with our new feature:")}
                    variant="brand"
                    className="fs-14 mt-md-auto swap-session__care-link"
                />
            </section>

            {isSwapSessionModalOpen && (
                <GuestParticipantConfirmSwapModal
                    show={isSwapSessionModalOpen}
                    onClose={handleCloseSwapSessionModal}
                    sessionOriginal={originalSession}
                    sessionSuggested={suggestedSession}
                    coach={coach}
                    handleSubmit={handleSubmitSwapSessionsClick}
                    mutationError={swapMutationError}
                    isMutationSuccessful={isSwapMutationSuccessful}
                    isSessionConfirmed={isSessionConfirmed}
                />
            )}
        </>
    );
};

const GuestParticipantContainer: NamedExoticComponent = memo(GuestParticipantContainerComponent);

export { GuestParticipantContainer };
