import React, { memo, startTransition, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import useDebounce from "common/hooks/use-debounce";
import useOnRouteChange from "common/hooks/use-route-change";
import CoachLayout from "main-app/layouts/CoachLayout";
import useParticipants from "main-app/api/use-participants";
import { CoachDashboardTab, SortKeysSession } from "main-app/constants";
import useGetCoachSessions from "main-app/api/use-coach-sessions";
import CareTeamLink from "main-app/shared/CareTeamLink";
import { isEmptyString } from "common/utils/gates";
import { getErrorMessages } from "common/utils/get-error-messages";
import { DEFAULT_PAGINATION_PAGE } from "common/hooks/use-pagination";
import CoachSetupScheduleModal from "main-app/widgets/coach-setup-schedule-modal/ui/CoachSetupScheduleModal";
import DateWidget from "main-app/shared/DateWidget";

import ParticipantsTable from "./participants/ParticipantsTable";
import SessionsTable from "./sessions/SessionsTable";
import GroupsTable from "./groups/GroupsTable";

import Header from "./Header";
import "./styles.scss";

interface IProps {}

const CoachDashboard: React.FC<IProps> = () => {
    const location = useLocation();
    const [searchValue, setSearchValue] = useState("");
    const [selectedCohort, setSelectCohort] = useState("");
    const [selectedPeriod, setSelectedPeriod] = useState("upcoming_sessions");
    const [sessionTimeOrderSort, setSessionTimeOrderSort] = useState(SortKeysSession.Desc);
    const [selectedTab, setSelectedTab] = useState<CoachDashboardTab>(location.pathname.slice(1) as CoachDashboardTab);
    const debouncedValue = useDebounce(searchValue, 300);
    const [showSetupModal, setShowSetupModal] = useState(false);
    const {
        data: participantsData,
        isLoading,
        isError: isErrorParticipants,
        error: errorParticipants
    } = useParticipants({
        search: debouncedValue,
        cohort: selectedCohort,
        enabled: location.pathname.includes(CoachDashboardTab.PARTICIPANTS)
    });
    const isGroupsOrSessionsTab =
        location.pathname.includes(CoachDashboardTab.SESSIONS) || location.pathname.includes(CoachDashboardTab.GROUP);

    const [currentPaginationPage, setCurrentPaginationPage] = useState<number>(DEFAULT_PAGINATION_PAGE);

    const {
        data: sessionData,
        isFetching: isFetchingSessions,
        isError,
        error
    } = useGetCoachSessions({
        search: debouncedValue,
        order: sessionTimeOrderSort,
        cohort: selectedCohort,
        period: selectedPeriod,
        enabled: isGroupsOrSessionsTab && (isEmptyString(selectedCohort) || !isNaN(parseInt(selectedCohort))),
        page: currentPaginationPage
    });

    useOnRouteChange(
        useCallback(() => {
            startTransition(() => {
                const tab = location.pathname.slice(1) as CoachDashboardTab;
                setSelectCohort("");
                setSelectedTab(tab);
                setCurrentPaginationPage(DEFAULT_PAGINATION_PAGE);
            });
        }, [location.pathname])
    );

    const changeCurrentPaginationPage = useCallback(
        (page: number): void => startTransition((): void => setCurrentPaginationPage(page)),
        []
    );

    const sortBySessionTime = useCallback((order: boolean) => {
        setSessionTimeOrderSort(order ? SortKeysSession.Desc : SortKeysSession.Asc);
    }, []);

    const onSelectSessionPeriod = useCallback((value: string) => {
        setSelectedPeriod(value);
        setCurrentPaginationPage(DEFAULT_PAGINATION_PAGE);
    }, []);

    const onShowSetupModal = useCallback((value: boolean) => {
        setShowSetupModal(value);
    }, []);

    return (
        <CoachLayout leftSidebar={null} headerTitle={<DateWidget className="coach-date-title" />}>
            <Header
                onSearch={setSearchValue}
                onClickSetupCalendar={onShowSetupModal}
                selectedPeriod={selectedPeriod}
                selectedTab={selectedTab}
                onSelectSessionPeriod={onSelectSessionPeriod}
            />
            {selectedTab === CoachDashboardTab.SESSIONS && (
                <SessionsTable
                    currentPaginationPage={currentPaginationPage}
                    handlePageChange={changeCurrentPaginationPage}
                    totalSessionsCount={sessionData?.totalSessionsCount}
                    sessions={sessionData?.sessions}
                    loading={isFetchingSessions}
                    searchValue={searchValue}
                    selectedCohort={selectedCohort}
                    error={isError ? getErrorMessages(error).join("") : null}
                    onSort={sortBySessionTime}
                />
            )}
            {selectedTab === CoachDashboardTab.PARTICIPANTS && (
                <ParticipantsTable
                    participants={participantsData ?? []}
                    error={isErrorParticipants ? getErrorMessages(errorParticipants)?.join("") : null}
                    loading={isLoading}
                    searchValue={searchValue}
                />
            )}
            {selectedTab === CoachDashboardTab.GROUP && (
                <GroupsTable
                    currentPaginationPage={currentPaginationPage}
                    handlePageChange={changeCurrentPaginationPage}
                    totalSessionsCount={sessionData?.totalSessionsCount}
                    sessions={sessionData?.sessions ?? []}
                    loading={isFetchingSessions}
                    searchValue={searchValue}
                    selectedCohort={selectedCohort}
                    error={isError ? getErrorMessages(error).join("") : null}
                    onSort={sortBySessionTime}
                />
            )}
            <CareTeamLink className="care-team-link" />
            <CoachSetupScheduleModal show={showSetupModal} onClose={() => onShowSetupModal(false)} />
        </CoachLayout>
    );
};

export default memo(CoachDashboard);
