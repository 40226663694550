import React, { type FC, type ReactElement } from "react";
import classNames from "classnames";

import { getFormattedGuestSessionDate } from "../utils";
import { getTimezoneAbbreviation } from "main-app/utils/date";

type TGuestParticipantSessionTimeProps = {
    startTime: string;
    endTime?: string;
    withDays?: boolean;
    withTimezone?: boolean;
    withTimezoneExtrabold?: boolean;
    withTimeExtrabold?: boolean;
    direction?: "row" | "column";
};

const GuestParticipantSessionTime: FC<TGuestParticipantSessionTimeProps> = ({
    startTime,
    endTime,
    withDays = false,
    withTimezone = false,
    withTimezoneExtrabold = false,
    withTimeExtrabold = false,
    direction = "column"
}: TGuestParticipantSessionTimeProps): ReactElement => (
    <p className={classNames("swap-session__session-block__date", `flex-${direction}`)}>
        <span className={classNames({ "font-extrabold": withTimeExtrabold })}>
            {getFormattedGuestSessionDate(startTime, withDays)}
            {endTime && (
                <>
                    <TimeSpacing />
                    {getFormattedGuestSessionDate(endTime, withDays)}
                </>
            )}
        </span>
        {direction === "row" && <>&nbsp;</>}
        {withTimezone && (
            <span className={classNames("color-gray font-sm", { "font-extrabold": withTimezoneExtrabold })}>
                {getTimezoneAbbreviation()}
            </span>
        )}
    </p>
);

const TimeSpacing: FC = (): ReactElement => <>&nbsp;-&nbsp;</>;

export { GuestParticipantSessionTime, type TGuestParticipantSessionTimeProps };
