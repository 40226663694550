import React, { type FC, type ReactElement } from "react";

import Coach from "main-app/models/coach";
import type { IAvailableGuestSessionModel } from "main-app/api/use-get-available-guest-sessions";
import type { TEmptyCallback } from "main-app/shared/types/functions";

import { GuestParticipantSessionAccordion } from "./GuestParticipantAccordion";
import type { IAvailableSessionDataGroupedByDay } from "../types";
import { WEEK_RANGE_SEPARATOR } from "../utils";

type TGuestParticipantSlideProps = {
    title: string;
    sessions?: IAvailableSessionDataGroupedByDay[];
    coach?: Coach;
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void;
    handleOpenSwapSessionModal: TEmptyCallback;
};

const GuestParticipantSlide: FC<TGuestParticipantSlideProps> = ({
    title,
    sessions,
    coach,
    handleSetSuggestedSession,
    handleOpenSwapSessionModal
}: TGuestParticipantSlideProps): ReactElement => (
    <div className="swap-session__accordion__container">
        <p className="m-0 font-extrabold">
            <span className="swap-session__accordion__title">{title.split(WEEK_RANGE_SEPARATOR)?.[0]}</span>
            {WEEK_RANGE_SEPARATOR}
            <span className="swap-session__accordion__title">{title.split(WEEK_RANGE_SEPARATOR)?.[1]}</span>
        </p>
        <div className="swap-session__accordion__list">
            {sessions.map(
                (s: IAvailableSessionDataGroupedByDay): ReactElement => (
                    <GuestParticipantSessionAccordion
                        key={`slide-accordion-${s.weekDay}`}
                        handleSetSuggestedSession={handleSetSuggestedSession}
                        handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                        session={s}
                        coach={coach}
                    />
                )
            )}
        </div>
    </div>
);

export { GuestParticipantSlide, type TGuestParticipantSlideProps };
