import { DateTime } from "luxon";

import { IAvailableGuestSessionModel } from "main-app/api/use-get-available-guest-sessions";
import { DATE_FORMAT } from "main-app/constants";
import { getHoursMinutesTime } from "main-app/utils/date";

import type { IAvailableSessionDataGroupedByDay, IAvailableSessionDataGroupedByWeek } from "./types";

const WEEK_RANGE_SEPARATOR = " - ";

function getGroupedAvailableSessionData(
    sessionData: IAvailableGuestSessionModel[]
): IAvailableSessionDataGroupedByWeek[] {
    const groupedByDayData: IAvailableSessionDataGroupedByDay[] = sessionData?.reduce(
        (acc, item): IAvailableSessionDataGroupedByDay[] => {
            const date: DateTime = DateTime.fromISO(item.startTime);

            const weekStart: DateTime = date.startOf("week").plus({ days: 0 });
            const weekEnd: DateTime = weekStart.plus({ days: 6 });

            const dateRange: string = `${weekStart.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH)}${WEEK_RANGE_SEPARATOR}${weekEnd.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH)}`;

            acc[dateRange] ||= {};

            for (let day: DateTime = weekStart; day <= weekEnd; day = day.plus({ days: 1 })) {
                acc[dateRange][day.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH_NUMBER)] ||= [];
            }

            acc[dateRange][date.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH_NUMBER)]?.push(item);

            return acc;
        },
        []
    );

    return Object.keys(groupedByDayData).map(dateRange => ({
        weekRange: dateRange,
        sessions: Object.keys(groupedByDayData[dateRange]).map(day => ({
            weekDay: day,
            sessions: groupedByDayData[dateRange][day]
        }))
    }));
}

function getFormattedGuestSessionDate(date: string, withDays: boolean = false): string {
    return `${withDays ? DateTime.fromISO(date).toFormat(DATE_FORMAT.SHORT_MONTH_DAY_NOT_PADDED) : ""}${withDays ? ", " : ""}${getHoursMinutesTime(DateTime.fromISO(date))}`;
}

function shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(originalSessionTime: string): boolean {
    return DateTime.fromISO(originalSessionTime).toMillis() >= DateTime.now().plus({ seconds: 60 }).toMillis();
}

export {
    WEEK_RANGE_SEPARATOR,
    getGroupedAvailableSessionData,
    getFormattedGuestSessionDate,
    shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime
};
