import React from "react";
import { matchPath, Navigate, useLocation } from "react-router-dom";
import User from "main-app/models/user";
import { AuthUrls, ConfigKeys, ParticipantUrls } from "main-app/constants";
import { StepUrls, dashboardURL, onboardingUrls } from "main-app/components/onboarding/constants";
import useRoles from "main-app/utils/hooks/use-roles";
import { getIndexPage } from "common/utils/get-index-page";
import { useStoreContext } from "main-app/context/GlobalStore";
import PageLoader from "main-app/shared/spinner/PageLoader";

type Props = {
    isAllowed: boolean;
    redirectPath?: string;
    children?: JSX.Element;
    isLoading?: boolean;
    user: User;
};

const ProtectedRoute = ({ children, isAllowed, redirectPath = AuthUrls.LOGIN, user, isLoading }: Props) => {
    const location = useLocation();
    const { isParticipant } = useRoles();
    const { appInit, getConfig } = useStoreContext();
    const isIndex = location.pathname === "/";
    const isWaitingListPage =
        matchPath(ParticipantUrls.WAITING_LIST_SSO, location.pathname) ||
        matchPath(ParticipantUrls.WAITING_LIST, location.pathname);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!user) {
        const state = sessionStorage.getItem("logout-action") ? null : { from: location.pathname };
        return <Navigate to={AuthUrls.LOGIN} state={state} replace />;
    }

    if (!appInit) {
        return <PageLoader />;
    }

    if (!user?.cohortExists && isParticipant && !isWaitingListPage) {
        const isSso = user?.unexpected;
        const url = isSso ? `/waiting-list/unrecognized-sso/${user?.organizationSlug}` : ParticipantUrls.WAITING_LIST;
        return <Navigate to={url} state={{ from: location }} replace />;
    }

    if (
        user.eligibleSelectModule &&
        getConfig(ConfigKeys.hasEnrolledModules) &&
        location.pathname !== ParticipantUrls.ASSIGN_MODULE
    ) {
        return <Navigate to={ParticipantUrls.ASSIGN_MODULE} replace />;
    }

    if (isIndex) {
        const path = getIndexPage(user, getConfig(ConfigKeys.showDashboard));
        return <Navigate to={path} state={{ from: location }} replace />;
    }

    if (isParticipant && !location.pathname.includes(ParticipantUrls.WAITING_LIST)) {
        if (!user?.onboardingFinished && !onboardingUrls.includes(location.pathname)) {
            if (!user.eligibleSelectModule) {
                return <Navigate to={StepUrls.ONBOARDING_REDIRECT_PAGE} state={{ from: location }} replace />;
                //
            }
        }

        if (user?.onboardingFinished && onboardingUrls.includes(location.pathname)) {
            return <Navigate to={dashboardURL} state={{ from: location }} replace />;
        }
    }

    if (!isAllowed) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    return children;
};

export default ProtectedRoute;
