import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { v4 as uuid } from "uuid";

import { ChapterComponent, ITrackPractice } from "Manager/chapter/models/types";
import useUpdateEffect from "common/hooks/use-update-effect";
import { sanitizeContent } from "main-app/utils/common";
import { withTranslation } from "common/utils/lang";

import TrackPracticeDescriptionBlock from "./TrackPractice/TrackPracticeDescriptionBlock";
import TrackPracticeForm from "./TrackPractice/TrackPracticeForm";
import NextButton from "./NextButton";
import { TrackPracticeSimulabPanel } from "./TrackPractice/TrackPracticeSimulabPanel";
import { TUsePracticeActivities, usePracticeActivities } from "./hooks";

interface IProps {
    component: ITrackPractice;
    chapterTitle: string;
    navBarTitle: string;
    onNext: (component: ChapterComponent, answer?: any, skip?: boolean) => void;
    onNextNavigation: () => void;
    phaseName?: string;
    isLast: boolean;
    disabledBtn: boolean;
    isSimulabPanelVisible: boolean;
    handleConversationScenarioNavigation(id: number): void;
}

const TrackPractice: React.FC<IProps> = ({
    component,
    onNext,
    isLast,
    chapterTitle,
    phaseName,
    navBarTitle,
    disabledBtn,
    isSimulabPanelVisible,
    handleConversationScenarioNavigation
}) => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [columns, setColumns] = useState([]);
    const [defaultFormValues, setDefaultFormValues] = useState(null);

    useEffect(() => {
        if (!component) {
            return;
        }

        if ("answer_data" in component) {
            const rows = component?.answer_data.rows?.map(row => {
                const { columns } = row;
                return {
                    uuid: uuid(),
                    columns: columns.reduce((cols, answeredCol) => {
                        component.columns.forEach((col, index) => {
                            if (col.uuid === answeredCol.column_uuid) {
                                cols.push({
                                    ...answeredCol,
                                    title: col.title.en,
                                    subtitle: col.subtitle.en,
                                    typing_prompt: col.typing_prompt.en,
                                    order: col.order || index + 1
                                });
                            }
                        });

                        return cols;
                    }, [])
                };
            });

            setDefaultFormValues({ rows });
        } else {
            setDefaultFormValues({
                rows: [
                    {
                        uuid: uuid(),
                        columns: component?.columns?.map((col, index) => ({
                            answer: "",
                            column_uuid: col.uuid,
                            title: col.title.en,
                            subtitle: col.subtitle.en,
                            typing_prompt: col.typing_prompt.en,
                            order: index + 1
                        }))
                    }
                ]
            });
        }
    }, [component]);

    useUpdateEffect(() => {
        if (defaultFormValues) {
            setColumns(defaultFormValues?.rows ?? []);
        }
    }, [defaultFormValues]);

    const { handleCompletedActivitiesCount, completedActivitiesCount, totalActivitiesCount }: TUsePracticeActivities =
        usePracticeActivities({
            component
        });

    const onCloseModal = () => {
        setShow(false);
    };

    const onOpenModal = e => {
        e.preventDefault();
        setShow(true);
    };

    const onClick = () => {
        onNext(component, columns, false);
    };

    const onSubmit = useCallback(
        value => {
            const columns = value.rows.map(row => ({
                columns: row.columns.map((col, index) => ({
                    answer: col?.answer,
                    column_uuid: col?.column_uuid,
                    title: col?.title,
                    subtitle: col?.subtitle,
                    typing_prompt: col?.typing_prompt,
                    order: col?.order || index + 1
                }))
            }));
            handleCompletedActivitiesCount(value?.rows, component?.practice_lab?.conversation_scenarios);
            onNext(component, columns, true);
            setColumns(columns);
            setShow(false);
        },
        [onNext, component, handleCompletedActivitiesCount]
    );

    return (
        <div className="track-practice__container">
            <h1 className="h1 font-extrabold color-black text-center text-center mb-4">{chapterTitle}</h1>
            <div className="wrapper">
                <div
                    className={classNames("col-sm-12", {
                        "col-lg-4": isSimulabPanelVisible,
                        "col-lg-7": !isSimulabPanelVisible
                    })}
                >
                    <div className="mt-3 mt-lg-0 px-3 px-lg-0">
                        <h3 className="h2 font-extrabold font-italic text-center mb-40">
                            {withTranslation(component?.title)}
                        </h3>
                        <div
                            className="content-text mb-40 track-practice__content-text"
                            dangerouslySetInnerHTML={{ __html: sanitizeContent(withTranslation(component?.content)) }}
                        />
                    </div>
                </div>
                <div
                    className={classNames("col-sm-12", {
                        "col-lg-8": isSimulabPanelVisible,
                        "col-lg-5": !isSimulabPanelVisible
                    })}
                >
                    <div className="track-practice__wrapper">
                        {isSimulabPanelVisible && (
                            <TrackPracticeSimulabPanel
                                handleConversationScenarioNavigation={handleConversationScenarioNavigation}
                                scenarios={component?.practice_lab?.conversation_scenarios}
                            />
                        )}

                        <div
                            className={classNames("track-practice-block-wrapper", {
                                "no-simulab": !isSimulabPanelVisible
                            })}
                        >
                            <TrackPracticeDescriptionBlock
                                component={component}
                                onOpenModal={onOpenModal}
                                isPracticeTitleVisible={isSimulabPanelVisible}
                            />
                        </div>

                        {isSimulabPanelVisible && (
                            <span className="completed-activities font-sm">
                                {t("chapter.trackPractice.trackPracticePanel.completedActivitiesCount", {
                                    completedCount: completedActivitiesCount,
                                    totalCount: totalActivitiesCount
                                })}
                            </span>
                        )}
                    </div>

                    {defaultFormValues && (
                        <TrackPracticeForm
                            defaultFormValues={defaultFormValues}
                            component={component}
                            onClose={onCloseModal}
                            show={show}
                            phaseName={phaseName}
                            navBarTitle={navBarTitle}
                            onSubmit={onSubmit}
                        />
                    )}
                </div>
            </div>
            <div className="text-center my-4">
                <NextButton onClick={onClick} isLast={isLast} disabled={disabledBtn} />
            </div>
        </div>
    );
};

export default TrackPractice;
