import { useQuery } from "@tanstack/react-query";
import { http } from "common/http";
import { ServerStateKeys } from "main-app/constants";
import { Participant, ParticipantApi } from "main-app/models/participants";
import Urls from "main-app/api/urls";
import { useCallback } from "react";

interface Query {
    search: string;
    cohort: string;
    enabled: boolean;
}

const getParticipants = async ({ search, cohort }: Query) => {
    const { data } = await http.get(Urls.participants(search, cohort));
    return data.results;
};

export default function useParticipants(query: Query) {
    return useQuery<ParticipantApi[], Error, Participant[]>(
        [ServerStateKeys.Participants, query],
        () => getParticipants(query),
        {
            select: useCallback(data => {
                return data.map(participant => new Participant(participant));
            }, []),
            enabled: query.enabled
        }
    );
}
