import React, { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { type AxiosError } from "axios";

import type { IAvailableGuestSessionModel } from "main-app/api/use-get-available-guest-sessions";
import Modal, { type IModalProps } from "main-app/shared/modal";
import Button from "main-app/shared/button/Button";
import Coach from "main-app/models/coach";
import Heading from "common/components/Heading/Heading";
import { isNullOrUndefined } from "common/utils/gates";
import { getErrorMessages } from "common/utils/get-error-messages";

import { default as GuestParticipantSessionBlock } from "./GuestParticipantSessionBlock";
import { GuestParticipantActionArrow } from "./GuestParticipantActionArrow";
import type { IOriginalSession } from "../types";

type TGuestParticipantConfirmSwapModalSubmitFunction = ({
    suggestedSessionId
}: {
    suggestedSessionId: number;
}) => Promise<void>;

type TGuestParticipantConfirmSwapModalProps = IModalProps & {
    sessionOriginal: IOriginalSession;
    sessionSuggested: IAvailableGuestSessionModel;
    coach?: Coach;
    isSessionConfirmed: boolean;
    mutationError?: AxiosError;
    handleSubmit: TGuestParticipantConfirmSwapModalSubmitFunction;
    isMutationSuccessful?: boolean;
};

const GuestParticipantConfirmSwapModal: FC<TGuestParticipantConfirmSwapModalProps> = ({
    show,
    onClose,
    sessionOriginal,
    sessionSuggested,
    coach,
    isSessionConfirmed,
    mutationError,
    handleSubmit,
    isMutationSuccessful
}: TGuestParticipantConfirmSwapModalProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <Modal
            disableBodyScroll
            show={show}
            onClose={onClose}
            modalBodyClass={"swap-session__modal"}
            headerContent={
                <Heading tag={"h2"} className={"text-center"}>
                    {!isSessionConfirmed
                        ? t("participantSide.swapSessionsScreen.swapModal.title")
                        : t("participantSide.swapSessionsScreen.swapModal.confirmedTitle")}
                </Heading>
            }
        >
            <div
                className={classNames("swap-session__modal__container", {
                    "with-message": isSessionConfirmed || !isNullOrUndefined(mutationError)
                })}
            >
                <div
                    className={classNames("swap-session__modal__content", {
                        "pt-0": isSessionConfirmed || !isNullOrUndefined(mutationError)
                    })}
                >
                    <div
                        className={classNames("swap-session__modal__content__message", {
                            "d-none": !isSessionConfirmed && !!isNullOrUndefined(mutationError)
                        })}
                    >
                        {isSessionConfirmed && (
                            <div className="swap-session__modal__content__message__mark">
                                <i />
                            </div>
                        )}
                        <span className="font-extrabold">
                            {isSessionConfirmed
                                ? t("participantSide.swapSessionsScreen.swapModal.confirmedMessage")
                                : getErrorMessages(mutationError)}
                        </span>
                    </div>

                    <div className="swap-session__modal__content__sessions">
                        <GuestParticipantSessionBlock
                            coachPhoto={sessionOriginal?.coach?.photo}
                            coachLastName={sessionOriginal.coach?.last_name}
                            coachName={sessionOriginal?.coach?.first_name}
                            sessionTime={sessionOriginal?.session_time}
                        />

                        <div className="swap-session__modal__content__sessions__arrow-wrapper">
                            <GuestParticipantActionArrow color="black" />
                        </div>

                        <GuestParticipantSessionBlock
                            coachPhoto={coach?.photo}
                            coachLastName={coach?.lastName}
                            coachName={coach?.firstName}
                            sessionTime={sessionSuggested?.startTime}
                        />
                    </div>

                    <Button
                        variant={isSessionConfirmed ? "outline-secondary" : "brand"}
                        onClick={() => handleSubmit({ suggestedSessionId: sessionSuggested.id })}
                        disabled={
                            !isNullOrUndefined(mutationError)
                                ? true
                                : isMutationSuccessful && isSessionConfirmed
                                  ? false
                                  : !isMutationSuccessful && isSessionConfirmed
                        }
                    >
                        <span className={classNames({ "font-extrabold": !isSessionConfirmed })}>
                            {!isSessionConfirmed
                                ? t("participantSide.swapSessionsScreen.swapModal.submit")
                                : t("participantSide.swapSessionsScreen.swapModal.confirmedSubmit")}
                        </span>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export {
    GuestParticipantConfirmSwapModal,
    type TGuestParticipantConfirmSwapModalProps,
    type TGuestParticipantConfirmSwapModalSubmitFunction
};
