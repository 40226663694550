import { CoachRatingSource, CoachingGoalStatus, SortKeysSession } from "main-app/constants";

class Urls {
    private static api = "/api";
    private static coachCalendar = `${Urls.api}/coach-calendar`;

    /* AUTH URLS */
    static me = () => `${Urls.api}/user/me/`;
    static login = () => `${Urls.api}/user/login/`;
    static login2fa = () => `${Urls.api}/user/login/2fa/`;
    static logout = () => `${Urls.api}/user/logout/`;
    static setPassword = (registerKey: string) => `${Urls.api}/user/set-password/${registerKey}/`;
    static resetPasswordEmail = () => `${Urls.api}/user/reset-password/`;
    static resend2fa = () => `${Urls.api}/user/login/2fa/resend-code/`;
    static ssoEmail = () => `${Urls.api}/user/org-sso/`;

    /* COACH URLS */
    static coach = () => `${Urls.api}/participants/get_coach_data/`;
    static participants = (search: string, cohort: string) =>
        `${Urls.api}/participants/?search=${search}&cohort=${cohort}`;
    static moduleByParticipant = (id: string) => `${Urls.api}/modules/by-participant/${id}/`;
    static participant = () => `${Urls.api}/participants/`;
    static assignModuleParticipant = () => `${Urls.api}/enrollments/`;
    static participantDataByCoach = (id: string) => `${Urls.api}/participants/${id}/`;
    static participantProgress = ({ moduleId, participantId }: { moduleId: number; participantId: number }) =>
        `${Urls.api}/modules/${moduleId}/results/${participantId}/`;
    static participantOnboardingInfo = (id: string) => `${Urls.api}/onboarding/participant/${id}/summary/`;
    static groupInfo = (id: string) => `${Urls.api}/coach-session/by-group/${id}/`;
    static coachSessions = (search: string, order: SortKeysSession, cohort: string, period: string, page: number) =>
        `${Urls.api}/coach-session/?search=${search}&page=${page}&ordering=${order}&cohort_id=${cohort}&session_period=${period}`;
    static assignedModuleDetails = (id: number) =>
        `${Urls.api}/coach-session/${id}/assign-modules-mark-attendance-detail/`;
    static assignModules = (id: number) => `${Urls.api}/coach-session/${id}/assign-modules-mark-attendance/`;

    /* COACH CALENDARS */
    static coachCalendarProfiles = () => `${Urls.coachCalendar}/profiles/`;
    static coachCalendarAttrs = () => `${Urls.coachCalendar}/oauth-attrs/`;
    static setPrimaryCalendar = () => `${Urls.coachCalendar}/profiles/set-primary/`;
    static zoomAccount = () => `${Urls.coachCalendar}/zoom-account/`;
    static avRulesAttrs = () => `${Urls.coachCalendar}/availability-rules-widget-attrs/`;
    static avRules = () => `${Urls.coachCalendar}/availability-rules/`;
    static confrimAvRules = () => `${Urls.coachCalendar}/availability-widget-attrs/`;
    static confirmSetupCalendar = () => `${Urls.coachCalendar}/confirm-setup/`;

    /* PARTICIPANT URLS */
    static modulesByCategory = () => `${Urls.api}/modules/by-category/`;
    static phases = (id: number) => `${Urls.api}/modules/${id}/phases/`;
    static resources = (id: number) => `${Urls.api}/resources/by-module/${id}/`;
    static chapter = (id: number) => `${Urls.api}/participant-chapter/${id}/get_chapter_data/`;
    static chapterComplete = (id: number) => `${Urls.api}/participant-chapter/${id}/`;
    static participantMe = () => `${Urls.api}/participants/me/`;
    static coachingSessions = (id?: number) => `${Urls.api}/coach-session/by-participant${id ? `/${id}/` : "/"}`;
    static getAvailableGuestSessions = ({ originalSessionId }: { originalSessionId: number }) =>
        `${Urls.api}/coach-session/${originalSessionId}/available-guest-sessions/`;
    static postAvailableGuestSessions = ({ swappedSessionId }: { swappedSessionId: number }) =>
        `${Urls.api}/coach-session/${swappedSessionId}/guest/`;
    static profileChange = () => `${Urls.api}/participants/set-personal-info/`;
    static setManagerInfo = (sessionId: number) => `${Urls.api}/coach-session/individual/${sessionId}/set-manager/`;

    /* Module Reflection URlS */
    static moduleReflectionQuestions = (moduleId: number) => `${Urls.api}/module-reflection/${moduleId}/questions/`;
    static moduleReflectionDiagnostic = () => `${Urls.api}/module-reflection/diagnostic-responses/`;
    static submitModuleReflection = () => `${Urls.api}/module-reflection/`;
    static skipSessionReflection = (sessionId: number) =>
        `${Urls.api}/coach-session/skip-post-session-reflection/${sessionId}/`;
    static coachRating = (source: CoachRatingSource) => `${Urls.api}/participant-feedback-coach/questions/${source}/`;
    static submitRating = (coachRatingId?: number) =>
        `${Urls.api}/participant-feedback-coach${coachRatingId ? `/${coachRatingId}/` : "/"}`;
    static npsQuestion = () => `${Urls.api}/participant-feedback-nps/question/`;
    static sendNps = (questionId?: string) =>
        `${Urls.api}/participant-feedback-nps${questionId ? `/${questionId}/` : "/"}`;

    /* Onboarding URLS */
    static onboardingProfileSetup = () => `${Urls.api}/onboarding/personal-info/`;
    static onboardingAboutManager = () => `${Urls.api}/onboarding/about-manager/`;
    static onboardingWaitListText = () => `${Urls.api}/onboarding/waiting_list/`;
    static onboardingStep1 = () => `${Urls.api}/onboarding/welcome/`;
    static onboardingStep2 = () => `${Urls.api}/onboarding/modules/`;
    static onboardingStep3 = () => `${Urls.api}/onboarding/diagnostic/`;
    static onboardingStep4 = () => `${Urls.api}/onboarding/diagnostic-summary/`;
    static onboardingStep5 = () => `${Urls.api}/onboarding/reflection-objective/`;
    static onboardingStep6 = () => `${Urls.api}/session-hold/`;
    static onboardingNext = () => `${Urls.api}/onboarding/next/`;
    static onboardingAnswer = () => `${Urls.api}/onboarding/answer/`;
    static onboardingPreviousSteps = () => `${Urls.api}/onboarding/previous-steps/`;
    static onboardingFinish = () => `${Urls.api}/onboarding/finish/`;

    /* Offboarding */
    static offboardingJourneyReflection = () => `${Urls.api}/offboarding/journey-final-questions/`;
    static submitOffboardingJourneyReflection = () => `${Urls.api}/offboarding/`;

    /* Sesssion URLS */
    static individualCoachesList = () => `${Urls.api}/participants/available-individual-coaches/`;
    static coachCalendarSetting = (coachId: number, session: number | "") =>
        `${Urls.api}/coach-calendar/${coachId}/session-widget-settings/${session}/`;
    static individualSessionAssign = (sessionId: number) =>
        `${Urls.api}/coach-session/individual/${sessionId}/schedule/`;
    static individualSessionReschedule = (sessionId: number) =>
        `${Urls.api}/coach-session/individual/${sessionId}/reschedule/`;
    static individualPairingQuestions = () => `${Urls.api}/pairing-question/`;

    /* Spot coaching */
    static productType = () => `${Urls.api}/product-type/`;
    static onboardingSteps = () => `${Urls.api}/onboarding/step-information/`;

    /* Profile */
    static uploadPhoto = () => `${Urls.api}/user/upload-photo/`;

    /* Coaching Plan */
    static coachingGoal = (participantId: number, query: CoachingGoalStatus) =>
        `${Urls.api}/coaching-plan/${participantId}/goal/?status=${query}`;
    static createCoachingGoal = participantId => `${Urls.api}/coaching-plan/${participantId}/goal/`;
    static editCoachingGoal = (participantId: number, goalId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/goal/${goalId}/`;
    static archiveCoachingGoal = (participantId: number, goalId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/goal/${goalId}/archive/`;
    static unarchiveCoachingGoal = (participantId: number, goalId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/goal/${goalId}/unarchive/`;
    static sortCoachingGoals = (participantId: number) => `${Urls.api}/coaching-plan/${participantId}/goal/sort/`;

    /* Behavioral Change*/
    static behavioralChange = (participantId: number, query: CoachingGoalStatus) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/?status=${query}`;
    static createBehavioralChange = (participantId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/`;
    static editBehavioralChange = (participantId: number, behavioralChangeId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/`;
    static archiveBehavioralChange = (participantId: number, behavioralChangeId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/archive/`;
    static unarchiveBehavioralChange = (participantId: number, behavioralChangeId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/unarchive/`;
    static sortBehavioralChange = (participantId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/sort/`;

    /* Behavioral Change Practice */
    static behavioralPractice = (participantId: number, behavioralChangeId: number, query: CoachingGoalStatus) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/practice/?status=${query}`;
    static createBehavioralPractice = (participantId: number, behavioralChangeId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/practice/`;
    static editBehavioralPractice = (participantId: number, behavioralChangeId: number, practiceId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/practice/${practiceId}/`;
    static archiveBehavioralPractice = (participantId: number, behavioralChangeId: number, practiceId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/practice/${practiceId}/archive/`;
    static unarchiveBehavioralPractice = (participantId: number, behavioralChangeId: number, practiceId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/practice/${practiceId}/unarchive/`;
    static sortBehavioralPractice = (participantId: number, behavioralChangeId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/behavioral-change/${behavioralChangeId}/practice/sort/`;

    /* Old belief  */
    static oldBelief = (participantId: number, status: CoachingGoalStatus) =>
        `${Urls.api}/coaching-plan/${participantId}/old-belief/?status=${status}`;
    static createOldBelief = (participantId: number) => `${Urls.api}/coaching-plan/${participantId}/old-belief/`;
    static editOldBelief = (participantId: number, oldBeliefId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/old-belief/${oldBeliefId}/`;
    static archiveOldBelief = (participantId: number, oldBeliefId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/old-belief/${oldBeliefId}/archive/`;
    static unarchiveOldBelief = (participantId: number, oldBeliefId: number) =>
        `${Urls.api}/coaching-plan/${participantId}/old-belief/${oldBeliefId}/unarchive/`;
    static sortOldBelief = (participantId: number) => `${Urls.api}/coaching-plan/${participantId}/old-belief/sort/`;

    /* SimulabUrls */

    /* Utils  */
    static ping = () => `${Urls.api}/user/ping/`;
}

export default Urls;
