import React, { useCallback, useMemo, useState } from "react";

import Avatar from "main-app/shared/avatar/Avatar";
import Select from "main-app/shared/select-default/Select";
import { Option } from "common/models/option";
import { GroupSessionParticipant } from "main-app/models/participants";
import { GroupCohort } from "main-app/models/cohort";
import { Status } from "main-app/constants";
import { AttendanceStatus, AttendanceStatuses } from "main-app/models/types";
import useRoles from "main-app/utils/hooks/use-roles";
import { withTranslation } from "common/utils/lang";
import { AttendeeRadioButton } from "./AttendeeRadioButton";
import { GuestParticipantAvatar } from "main-app/shared/GuestParticipantAvatar";

interface IProps {
    participant: GroupSessionParticipant;
    cohort: GroupCohort;
    enrollmentRank: number;
    error: boolean;
    canApplyModules: boolean;
    onModuleSelect: (assignedModule: any) => void;
    onMarkAttendance: (participant: GroupSessionParticipant & { status: AttendanceStatus }) => void;
}

const AttendeeRow: React.FC<IProps> = ({
    participant,
    cohort,
    onModuleSelect,
    onMarkAttendance,
    enrollmentRank,
    canApplyModules,
    error
}) => {
    const { isCoach } = useRoles();

    const [marked, setMarked] = useState<{ checked: boolean; name: AttendanceStatus | "" }>({
        checked: !!participant.attendanceStatus,
        name: participant.attendanceStatus || ""
    });

    const hasEnrollment = useCallback(
        (moduleId: number) => {
            return participant.enrolledModules.some(module => module.id === moduleId);
        },
        [participant]
    );

    const options = useMemo<Option[]>(() => {
        return (
            cohort?.modules?.map(
                module =>
                    new Option({
                        id: module.id,
                        label: withTranslation(module.version),
                        value: module.id,
                        disabled: hasEnrollment(module.id)
                    })
            ) ?? []
        );
    }, [cohort, hasEnrollment]);

    const optionsList = useMemo(() => {
        const sortedOptionsList = [...options].sort((a, b) => `${a.label}`.localeCompare(`${b.label}`));
        return [new Option({ id: "", label: "Assign Module", value: null, disabled: false }), ...sortedOptionsList];
    }, [options]);

    const onSelect = useCallback(
        option => {
            onModuleSelect({
                module: option.value,
                participant: participant.id,
                cohort: cohort.id
            });
        },
        [cohort, participant, onModuleSelect]
    );

    const nextRankEnrollment = useMemo(() => {
        const rankIndex = participant.enrolledModules.length === 1 && enrollmentRank === 0 ? 0 : enrollmentRank;
        return participant?.enrolledModules?.[rankIndex];
    }, [participant, enrollmentRank]);

    const disabledOptionIndex = useMemo(() => {
        if (nextRankEnrollment && nextRankEnrollment?.status !== Status.Unassigned) {
            return optionsList.findIndex(option => option.id === nextRankEnrollment.id);
        }
        return 0;
    }, [optionsList, nextRankEnrollment]);

    const hasNextRankEnrollment = useMemo(
        () => !!nextRankEnrollment && nextRankEnrollment?.status !== Status.Unassigned,
        [nextRankEnrollment]
    );

    const isLastModuleUnassigned =
        participant.enrolledModules[participant.enrolledModules.length - 1]?.status === Status.Unassigned;

    const endOfJourneyReached = useMemo(
        () =>
            (participant.enrolledModules.length === participant.journeyLength &&
                disabledOptionIndex < 1 &&
                !isLastModuleUnassigned) ||
            enrollmentRank > participant.journeyLength,
        [enrollmentRank, participant, cohort]
    );

    const handleRadioButtonClick = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const {
                checked,
                dataset: { id }
            } = event.target;

            setMarked({ checked, name: id as AttendanceStatus });
            onMarkAttendance({ ...participant, status: id as AttendanceStatus });
        },
        [onMarkAttendance]
    );

    return (
        <div className="row mt-3 align-items-center attendee-row">
            <div className="col-4">
                <div className="d-flex align-items-center">
                    <div>
                        <div className="flex-1">
                            {participant?.isGuest ? (
                                <GuestParticipantAvatar url={participant?.photo} />
                            ) : (
                                <Avatar url={participant?.photo} className="participant-image" />
                            )}
                        </div>
                    </div>

                    <span className="font-extrabold ml-2">
                        {participant?.firstName} {participant?.lastName}
                    </span>
                </div>
            </div>
            <div className="col-4">
                <div className="d-flex justify-content-center">
                    <AttendeeRadioButton
                        status={AttendanceStatuses.NOT_PRESENT}
                        className="mr-2"
                        marked={marked}
                        id={participant.id}
                        type="cross"
                        name="attendee"
                        handleRadioButtonClick={handleRadioButtonClick}
                    />
                    <AttendeeRadioButton
                        status={AttendanceStatuses.PRESENT}
                        marked={marked}
                        id={participant.id}
                        name="attendee"
                        type="check"
                        handleRadioButtonClick={handleRadioButtonClick}
                    />
                </div>
            </div>
            <div className="col-4">
                {isCoach && canApplyModules && (
                    <>
                        {(marked.name === AttendanceStatuses.PRESENT || hasNextRankEnrollment) &&
                        !endOfJourneyReached ? (
                            <Select
                                backgroundWhite
                                optionsList={optionsList}
                                onSelect={onSelect}
                                disabled={hasNextRankEnrollment}
                                defaultOptionIndex={disabledOptionIndex}
                                error={error}
                            />
                        ) : (
                            (endOfJourneyReached || marked.name === AttendanceStatuses.NOT_PRESENT) && (
                                <div className="text-center">
                                    <span className="color-gray">
                                        {endOfJourneyReached ? "End of journey reached!" : "Unavailable to assign"}
                                    </span>
                                </div>
                            )
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AttendeeRow;
